import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class JobsService {

  constructor(public afs: AngularFirestore) { }

  async getAllJobs() {
    return await this.afs.collection('jobs')  // , ref => ref.orderBy('myDate', 'desc'));

  }

}
