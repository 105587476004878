import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import firebase from '@firebase/app';
import '@firebase/firestore';

@Injectable({
  providedIn: 'root'
})
export class PlansService {

  constructor(public afs: AngularFirestore) { }

  async getPlans() {
    return await this.afs.collection('plans')  // , ref => ref.orderBy('myDate', 'desc'));

  }

  updatePlan(label, id) {
    return this.afs.collection('plans').doc(id).update({label: label, value: label})
  }

  updateBluePlan(label, id) {
    console.log(label, id)
    return this.afs.collection('planbenefits').doc(id).update({blue: label})
  }

  updateGreenPlan(label, id) {
    return this.afs.collection('planbenefits').doc(id).update({green: label})
  }

  updateYellowPlan(label, id) {
    return this.afs.collection('planbenefits').doc(id).update({yellow: label})
  }

  updateRedPlan(label, id) {
    return this.afs.collection('planbenefits').doc(id).update({red: label})
  }

  async getHealthPlansBenefits() {
    return await this.afs.collection('planbenefits');
  }

  addPlan(plan, id) {
    this.afs.collection('hospital').doc(id).update({ plans: firebase.firestore.FieldValue.arrayUnion(plan) }) 

  }

  removePlan(plan, id) {
    this.afs.collection('hospital').doc(id).update({ plans: firebase.firestore.FieldValue.arrayRemove(plan) }) 

  }

}
