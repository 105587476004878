import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class MessagesService {

  constructor(public afs: AngularFirestore) { }

  async getAllMessages() {
    return await this.afs.collection('messages')  // , ref => ref.orderBy('myDate', 'desc'));

  }
}
