import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { MdbCardBodyComponent } from 'ng-uikit-pro-standard';

@Injectable({
  providedIn: 'root'
})
export class BlogService {

  constructor(public afs: AngularFirestore) { }

  uploadBlog (author: string, body: string, date: number, excerpt: string, image: string, title: string) {
    let id = this.afs.createId();
    this.afs.collection('blogs').doc(id).set({author: author, body: body, date: date, excerpt: excerpt, id: id, image: image, title: title })

  }

  async getAllBlogs() {
    return await this.afs.collection('blogs');
  }

  removeBlog (id: string) {
    
    this.afs.collection('blogs').doc(id).delete();
  }
}
