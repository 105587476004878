import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class HospitalsService {

  constructor(public afs: AngularFirestore) { }

  async getAllHospitals() {
    return await this.afs.collection('hospital')  // , ref => ref.orderBy('myDate', 'desc'));

  }

  updatePlan(label, id) {
    return this.afs.collection('hospital').doc(id).update({enable: label})
  }

  addHospital(hospitalName){
console.log(hospitalName);

let id = this.afs.createId()
let plans = []
this.afs.collection('hospital').doc(id).set({id,name:hospitalName,label:hospitalName,value:hospitalName,plans,enable:true})
console.log(id);

  }
}
 