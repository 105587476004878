import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './pages/login/login.component';
import { AdmindashboardComponent } from './pages/admindashboard/admindashboard.component';
import { UsersComponent } from './pages/users/users.component';
import { PlansComponent } from './pages/plans/plans.component';
import { HealthplansComponent } from './pages/healthplans/healthplans.component';
import { MessagesComponent } from './pages/messages/messages.component';
import { JobsComponent } from './pages/jobs/jobs.component';
import { HospitalsComponent } from './pages/hospitals/hospitals.component';
import { BlogsComponent } from './pages/blogs/blogs.component';
import { BanksComponent } from './pages/banks/banks.component';



const routes: Routes = [
    { path: '', redirectTo: '/login', pathMatch: 'full' },
    { path: 'login', component: LoginComponent },
    { path: 'admindashboard', component: AdmindashboardComponent},
    { path: 'users', component: UsersComponent},
    { path: 'plans', component: PlansComponent},
    { path: 'healthplans', component: HealthplansComponent},
    { path: 'messages', component: MessagesComponent},
    { path: 'jobs', component: JobsComponent},
    { path: 'hospitals', component: HospitalsComponent},
    { path: 'blogs', component: BlogsComponent},
    { path: 'banks', component: BanksComponent},
    {path: '**', component: LoginComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }