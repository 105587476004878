
import { EditorModule } from '@tinymce/tinymce-angular';
import { AngularFireStorage } from '@angular/fire/storage';
import * as moment from 'moment';

import { Component, OnInit, ElementRef, HostListener, AfterViewInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { MdbTableDirective, MdbTablePaginationComponent, IMyOptions } from 'ng-uikit-pro-standard';

import { ModalComponent } from 'src/app/modals/modal/modal.component';
import { MDBModalRef, MDBModalService } from 'ng-uikit-pro-standard';
import { FormControl, FormGroup, Validators } from '@angular/forms';




import * as firebase from 'firebase/app';
import 'firebase/functions';
import { BlogService } from 'src/app/services/blog.service';

declare var require: any

@Component({
  selector: 'app-blogs',
  templateUrl: './blogs.component.html',
  styleUrls: ['./blogs.component.scss']
})
export class BlogsComponent implements OnInit, AfterViewInit {

  modalRef: MDBModalRef;
  @ViewChild(MdbTableDirective, { static: true }) mdbTable: MdbTableDirective;
  @ViewChild(MdbTablePaginationComponent, { static: true }) mdbTablePagination: 
  
  
  
  
  
  
  
  
  MdbTablePaginationComponent;
  // @ViewChild('row', { static: true }) row: ElementRef;
  // @ViewChild('demoBasic', { static: true }) demoBasic: ModalDirective;
  validatingForm: FormGroup;

  elements: any = [];
  elementsT: any = [];
  headElements = ['Date', 'Title', 'Author', 'Action'];
  

  optionsSelectProductStatus: Array<any>;
  optionsSelectProductPromo: Array<any>;
  public myDatePickerOptions: IMyOptions = {
    // Your options
    };
  
  

  ordersElements: any = [];
  
  
  ordersHeadElements = ['ID', 'Date', 'Amount', 'Status', 'Payment Date', 'Payment Amount', 'Balance', 'Action'];
  ordersMasterHeadElements = ['Name', 'Price', 'Quantity', 'Promo', 'Action'];




  searchText: string = '';
  previous: string;

  maxVisibleItems: number = 100;

  public uploadPercent: any;
  public uploadFlag: any;
  public picture: any;
  public ImageFilePath: string = "../../../assets/upload.jpeg";
  public blogAuthor: string = "Sample Author";
  public blogBody: any = "Sample Body";
  public blogDate: any = Date.now();
  public blogExcerpt: any = "Sample Excerpt";
  public blogImage: string;
  public blogTitle: string = "Sample Title";
  public fileUploadedSuccess: boolean = true;

  constructor(public cdRef: ChangeDetectorRef, public afstorage: AngularFireStorage, public blogService: BlogService) { }

  @HostListener('input') oninput() {
    this.mdbTablePagination.searchText = this.searchText;
  }

  ngOnInit() {

    this.blogService.getAllBlogs().then((returnedCustomers) => {
      returnedCustomers.valueChanges().subscribe( ref => {
        this.elements = ref;
        // this.planLabel = this.elements.label;
        console.log(ref)
        console.log(this.elements.label)
        // console.log('this is the lement content', this.elements)
        this.mdbTable.setDataSource(this.elements);
    this.elements = this.mdbTable.getDataSource();
    this.previous = this.mdbTable.getDataSource();
        
      })
    });

    


    

  }
  

  changeListener(event: any) : void
{
  console.log('im here')
  // this.presentLoadingWithOptions();
this.uploadFlag = true;
   console.log(event)
console.log(Date.now());


    const file = event.target.files[0];
   //  this.mypic = event.target.files[0];
    const filePath = 'images/' + Date.now() + '.jpg';
    const fileRef = this.afstorage.storage.ref(filePath);
    const task = fileRef.put(file);

    this.uploadPercent = task.snapshot.state;
     //  percentageChanges();

    //console.log(this.uploadPercent);



    task.then((snap)=>{
      snap.ref.getDownloadURL().then((url)=> {
        this.uploadPercent =  task.snapshot.state;
        this.uploadDocument(url);
        //this.presentAlertRadio();
        this.ImageFilePath = url;
        this.blogImage = url;
        if (this.ImageFilePath.length > 27) {
          this.fileUploadedSuccess = false;
        }
          console.log(this.fileUploadedSuccess)
                
        console.log("DOWNLOAD URL = ", url);
       //  this.ps.updatePicture(uid, url);
        this.picture = url
        localStorage.setItem('componantAFilters', JSON.stringify(this.picture))
       })
     })
   }



   uploadDocument(information: string){
    // const fileName = this.generateRandomName() + '.jpg';
    const fileRef = this.afstorage.storage.ref().child('images/');
    const uploadTask = fileRef.putString(information);

   //  return this.auths.getSomeone(userID)

    return new Promise((resolve, reject) => {
        uploadTask.on('state_changed', snapshot => {
    },  error => {
          reject(error);

    }, () => {
      uploadTask.snapshot.ref.getDownloadURL().then(function(downloadURL) {
        
        
        
        resolve ( {downloadURL});
        console.log(downloadURL)
      });

    });

    });
    // return this.db.list('files');
  }

  uploadBlogToServer() {

  }

  ngAfterViewInit() {
    this.mdbTablePagination.setMaxVisibleItemsNumberTo(this.maxVisibleItems);

    this.mdbTablePagination.calculateFirstItemIndex();
    this.mdbTablePagination.calculateLastItemIndex();
    this.cdRef.detectChanges();
  }

  addNewRow() {
    this.mdbTable.addRow({
      id: this.elements.length.toString(),
      first: 'Wpis ' + this.elements.length,
      last: 'Last ' + this.elements.length,
      handle: 'Handle ' + this.elements.length
    });
    this.emitDataSourceChange();
  }

  addNewRowAfter() {
    this.mdbTable.addRowAfter(1, {id: '2', first: 'Nowy', last: 'Row', handle: 'Kopytkowy'});
    this.mdbTable.getDataSource().forEach((el: any, index: any) => {
      el.id = (index + 1).toString();
    });
    this.emitDataSourceChange();
  }

  removeLastRow() {
    this.mdbTable.removeLastRow();
    this.emitDataSourceChange();
    this.mdbTable.rowRemoved().subscribe((data: any) => {
      // console.log(data);
    });
  }

  removeRow() {
    this.mdbTable.removeRow(1);
    this.mdbTable.getDataSource().forEach((el: any, index: any) => {
      el.id = (index + 1).toString();
    });
    this.emitDataSourceChange();
    this.mdbTable.rowRemoved().subscribe((data: any) => {
      // console.log(data);
    });
  }

  emitDataSourceChange() {
    this.mdbTable.dataSourceChange().subscribe((data: any) => {
      // console.log(data);
    });
  }

  searchItems() {
    const prev = this.mdbTable.getDataSource();

    if (!this.searchText) {
      this.mdbTable.setDataSource(this.previous);
      this.elements = this.mdbTable.getDataSource();
    }

    if (this.searchText) {
      this.elements = this.mdbTable.searchLocalDataBy(this.searchText);
      this.mdbTable.setDataSource(prev);
    }

    this.mdbTablePagination.calculateFirstItemIndex();
    this.mdbTablePagination.calculateLastItemIndex();

    this.mdbTable.searchDataObservable(this.searchText).subscribe(() => {
      this.mdbTablePagination.calculateFirstItemIndex();
      this.mdbTablePagination.calculateLastItemIndex();
    });

}

removeBlog(id) {
  this.blogService.removeBlog(id)
}

addBlog() {

 let theDate = moment(this.blogDate).unix();
  console.log(this.blogAuthor, this.blogBody, theDate, this.blogExcerpt, this.blogImage, this.blogTitle)
   this.blogService.uploadBlog(this.blogAuthor, this.blogBody, theDate, this.blogExcerpt, this.blogImage, this.blogTitle)
}
}