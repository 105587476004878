
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { AgmCoreModule } from '@agm/core';
import { AppComponent } from './app.component';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { PopoverModule, CardsModule, WavesModule, ButtonsModule } from 'ng-uikit-pro-standard';
import { ModalModule, InputsModule } from 'ng-uikit-pro-standard'
import { EditorModule } from '@tinymce/tinymce-angular';
import { MDBSpinningPreloader, MDBBootstrapModulesPro, ToastModule } from 'ng-uikit-pro-standard';
import { environment } from 'src/environments/environment';
import { RouterModule } from '@angular/router';
import { LoginComponent } from './pages/login/login.component';
import { AppRoutingModule } from './app-routing.module';
import { AdmindashboardComponent } from './pages/admindashboard/admindashboard.component';

import { ModalComponent } from './modals/modal/modal.component';
import { NavigationComponent } from './pages/navigation/navigation.component';
import { UsersComponent } from './pages/users/users.component';
import { PlansComponent } from './pages/plans/plans.component';
import { HealthplansComponent } from './pages/healthplans/healthplans.component';
import { MessagesComponent } from './pages/messages/messages.component';
import { JobsComponent } from './pages/jobs/jobs.component';
import { HospitalsComponent } from './pages/hospitals/hospitals.component';
import { BlogsComponent } from './pages/blogs/blogs.component';
import { BanksComponent } from './pages/banks/banks.component';
import { AddComponent } from './pages/plans/add/add.component';
import { ListComponent } from './pages/plans/list/list.component';


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    AdmindashboardComponent,
    ModalComponent,
    NavigationComponent,
    UsersComponent,
    PlansComponent,
    HealthplansComponent,
    MessagesComponent,
    JobsComponent,
    HospitalsComponent,
    BlogsComponent,
    BanksComponent,
    AddComponent,
    ListComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    EditorModule,
    HttpClientModule,
    ModalModule.forRoot(),
    InputsModule.forRoot(),
    CardsModule.forRoot(),
    ButtonsModule.forRoot(),
    PopoverModule.forRoot(),
    ToastModule.forRoot(),
    MDBBootstrapModulesPro.forRoot(),
    AngularFireModule.initializeApp(environment.firebase),
        AngularFireAuthModule,
        AngularFirestoreModule.enablePersistence(),
        AngularFireStorageModule,
        AppRoutingModule,
    AgmCoreModule.forRoot({
      // https://developers.google.com/maps/documentation/javascript/get-api-key?hl=en#key
      apiKey: 'Your_api_key'
    })
  ],
  entryComponents: [ ModalComponent ],
  providers: [MDBSpinningPreloader, WavesModule],
  bootstrap: [AppComponent],
  schemas:      [ NO_ERRORS_SCHEMA ]
})
export class AppModule { }
