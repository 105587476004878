import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'Ives Medicare';
  // public status= false;

  public currentUser: boolean = false;
  constructor(public afa: AngularFireAuth) { }

ngOnInit() {

  this.afa.auth.onAuthStateChanged( user => {
    if (user) {
      this.currentUser = true;
  }
    })



    
}
}



