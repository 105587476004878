import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { CustomersService } from 'src/app/services/customer.service';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit {

  public currentUser: boolean = false;
  public totalUsers: any;

  constructor(public afa: AngularFireAuth, public router: Router, public customerService: CustomersService) { }

  ngOnInit() {

    this.afa.auth.onAuthStateChanged( user => {
      if (user) {
        this.currentUser = true;
    }
      })

      this.customerService.returnNumberOfUsers().valueChanges().subscribe(ref => {
        this.totalUsers = ref.length;
      })
      
       console.log(this.totalUsers)
}

logOut() {
  this.afa.auth.signOut();
  this.router.navigate(['/'])
  this.currentUser = false;

}
}
