// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyATPIdr1lrmoHM5Xkvzr9rBUyz6T2YpzFI",
    authDomain: "iveshospital.firebaseapp.com",
    databaseURL: "https://iveshospital.firebaseio.com",
    projectId: "iveshospital",
    storageBucket: "iveshospital.appspot.com",
    messagingSenderId: "480588648954",
    appId: "1:480588648954:web:f1b11d6c0b99daa1fb9443"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
