import { Component, OnInit, ElementRef, HostListener, AfterViewInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { MdbTableDirective, MdbTablePaginationComponent, IMyOptions } from 'ng-uikit-pro-standard';

import { ModalComponent } from 'src/app/modals/modal/modal.component';
import { MDBModalRef, MDBModalService } from 'ng-uikit-pro-standard';
import { FormControl, FormGroup, Validators } from '@angular/forms';



import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

import numWords from 'num-words';
import { ActivatedRoute } from '@angular/router';
import { analytics } from 'firebase';
import * as firebase from 'firebase/app';
import 'firebase/functions';

declare var require: any

const numWords = require('num-words');

@Component({
  selector: 'app-admindashboard',
  templateUrl: './admindashboard.component.html',
  styleUrls: ['./admindashboard.component.scss']
})

export class AdmindashboardComponent implements OnInit, AfterViewInit {
  modalRef: MDBModalRef;
  @ViewChild(MdbTableDirective, { static: true }) mdbTable: MdbTableDirective;
  @ViewChild(MdbTablePaginationComponent, { static: true }) mdbTablePagination: 
  
  
  
  
  
  
  
  
  MdbTablePaginationComponent;
  // @ViewChild('row', { static: true }) row: ElementRef;
  // @ViewChild('demoBasic', { static: true }) demoBasic: ModalDirective;
  validatingForm: FormGroup;

  elements: any = [];
  elementsT: any = [];
  headElements = ['customer_id', 'image', 'name', 'email', 'phone', 'location', 'salesRep', 'action'];
  // headElements = ['id', 'first', 'last', 'handle'];

  optionsSelectProductStatus: Array<any>;
  optionsSelectProductPromo: Array<any>;
  public myDatePickerOptions: IMyOptions = {
    // Your options
    };
  public messages: any;
  public selectedP: any;
  public selectedV: any;
  public outGoingMessage: string;
  

  ordersElements: any = [];
  
  
  ordersHeadElements = ['ID', 'Date', 'Amount', 'Status', 'Payment Date', 'Payment Amount', 'Balance', 'Action'];
  ordersMasterHeadElements = ['Name', 'Price', 'Quantity', 'Promo', 'Action'];




  searchText: string = '';
  previous: string;

  maxVisibleItems: number = 100;

  public products: any;
  public currentUserPicture: string;
  public currentUserID: string;
  public currentGudyID: string;
  public pictureStatus: boolean = true;
  public selectedValue: string;
  public paidAmount: number;
  public currentRow: string;
  public payDate: any;
  public rotation: object[] = [];
  public theAdmin: string;

  public totalLoadingProducts: number = 0;
  public totalPendingProducts: number = 0;
  public totalShippingProducts: number = 0;

  public totalLoadingProductsAmount: number = 0;
  public totalPendingProductsAmount: number = 0;
  public totalShippingProductsAmount: number = 0;
  


  public modalOptions = {
    backdrop: true,
    keyboard: true,
    focus: true,
    show: false,
    ignoreBackdropClick: false,
    class: '',
    containerClass: '',
    animated: true,
    data: {
        heading: 'Modal heading',
        content: { heading: 'Content heading', description: 'Content description'}
    }
  }


  constructor(
    public cdRef: ChangeDetectorRef, 
    
    public modalService: MDBModalService,
    public route: ActivatedRoute,
    ) {}

  @HostListener('input') oninput() {
    this.mdbTablePagination.searchText = this.searchText;
  }

  ngOnInit() {

    // this.customerService.getAllUsers().then((returnedCustomers) => {
    //   returnedCustomers.valueChanges().subscribe( ref => {
    //     this.elements = ref;
    //     // console.log('this is the lement content', this.elements)
    //     this.mdbTable.setDataSource(this.elements);
    // this.elements = this.mdbTable.getDataSource();
    // this.previous = this.mdbTable.getDataSource();
        
    //   })
    // });

    

    this.validatingForm = new FormGroup({
      modalFormSubscriptionName: new FormControl('', Validators.required),
      modalFormSubscriptionEmail: new FormControl('', Validators.email)
    });


    

  }

  ngAfterViewInit() {
    this.mdbTablePagination.setMaxVisibleItemsNumberTo(this.maxVisibleItems);

    this.mdbTablePagination.calculateFirstItemIndex();
    this.mdbTablePagination.calculateLastItemIndex();
    this.cdRef.detectChanges();
  }

  addNewRow() {
    this.mdbTable.addRow({
      id: this.elements.length.toString(),
      first: 'Wpis ' + this.elements.length,
      last: 'Last ' + this.elements.length,
      handle: 'Handle ' + this.elements.length
    });
    this.emitDataSourceChange();
  }

  addNewRowAfter() {
    this.mdbTable.addRowAfter(1, {id: '2', first: 'Nowy', last: 'Row', handle: 'Kopytkowy'});
    this.mdbTable.getDataSource().forEach((el: any, index: any) => {
      el.id = (index + 1).toString();
    });
    this.emitDataSourceChange();
  }

  removeLastRow() {
    this.mdbTable.removeLastRow();
    this.emitDataSourceChange();
    this.mdbTable.rowRemoved().subscribe((data: any) => {
      // console.log(data);
    });
  }

  removeRow() {
    this.mdbTable.removeRow(1);
    this.mdbTable.getDataSource().forEach((el: any, index: any) => {
      el.id = (index + 1).toString();
    });
    this.emitDataSourceChange();
    this.mdbTable.rowRemoved().subscribe((data: any) => {
      // console.log(data);
    });
  }

  emitDataSourceChange() {
    this.mdbTable.dataSourceChange().subscribe((data: any) => {
      // console.log(data);
    });
  }

  searchItems() {
    const prev = this.mdbTable.getDataSource();

    if (!this.searchText) {
      this.mdbTable.setDataSource(this.previous);
      this.elements = this.mdbTable.getDataSource();
    }

    if (this.searchText) {
      this.elements = this.mdbTable.searchLocalDataBy(this.searchText);
      this.mdbTable.setDataSource(prev);
    }

    this.mdbTablePagination.calculateFirstItemIndex();
    this.mdbTablePagination.calculateLastItemIndex();

    this.mdbTable.searchDataObservable(this.searchText).subscribe(() => {
      this.mdbTablePagination.calculateFirstItemIndex();
      this.mdbTablePagination.calculateLastItemIndex();
    });
  }

  


  


}



  






