import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class BanksService {

  constructor(public afs: AngularFirestore) { }

  async getAllBanks() {
    return await this.afs.collection('banks')  // , ref => ref.orderBy('myDate', 'desc'));

  }

  addBank(bank) {
    let id = this.afs.createId();
    return this.afs.collection('banks').doc(id).set({id: id, name: bank, label: bank, value: bank})
  }

  deleteBank(id) {
    return this.afs.collection('banks').doc(id).delete()
  }
}
