import { Component, OnInit } from '@angular/core';
import { LoginService } from 'src/app/services/login.service';
import { Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { PopoverModule, WavesModule, PopoverDirective} from 'ng-uikit-pro-standard';


import * as firebase from 'firebase/app';
import 'firebase/functions';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  public email: string;
  public forgotten_email: string;
  public password: string;
  public initialStand: boolean = false;
  public initialOKStand: boolean = true;
  admin: any;
  public adminID: string;
  public loc: string;

  constructor(
    public loginService: LoginService, 
    public router: Router,
    public afa: AngularFireAuth, 
    public afs: AngularFirestore) 
              { 

              }

   signIn() {
     // console.log('im here', this.email, this.password)
     


    this.afa.auth.signInWithEmailAndPassword(this.email, this.password).then(()=>{
      this.afa.auth.currentUser.getIdTokenResult().then((idTokenResult) => {
        // console.log(idTokenResult.claims.user_id)
        // console.log(idTokenResult.claims.location)
        // console.log(idTokenResult.claims.salesrep)
        // console.log(idTokenResult)
        // console.log(idTokenResult.claims)
        
        this.adminID = idTokenResult.claims.user_id;
        this.loc = idTokenResult.claims.location
        
        if (idTokenResult.claims.admin) {
          
          this.router.navigate(['/users'])
          // allowAccess();
        }  else {
        //   let element: HTMLElement = document.getElementById('examplebut') as HTMLElement;
        // element.click();

        // let okelement: HTMLElement = document.getElementById('okbut') as HTMLElement;
        // okelement.hidden = false;
        this.showErrorInfo("You are not  an Admin");
        }
      }).catch((error) => {
        this.showErrorInfo(error);

        // let okelement: HTMLElement = document.getElementById('okbut') as HTMLElement;
        // okelement.hidden = false;
        
      
        
      })
      
      
      }).catch((error)=>{
        this.showErrorInfo(error);
        
      })
  }
   





  ngOnInit() {
    
    // var addLocation = firebase.functions().httpsCallable('addSalesRepLocation');
    // addLocation({email: 'lagos@gudyfoods.com', location: 'lagos'}).then(result=> {
    //   // console.log(result);
    // }).catch((error)=>{
    //   // console.log(error);
    // })

    // addLocation({email: 'north@gudyfoods.com', location: 'north'}).then(result=> {
    //   // console.log(result);
    // }).catch((error)=>{
    //   // console.log(error);
    // })

    // addLocation({email: 'south@gudyfoods.com', location: 'south'}).then(result=> {
    //   // console.log(result);
    // }).catch((error)=>{
    //   // console.log(error);
    // })

    // addLocation({email: 'west@gudyfoods.com', location: 'west'}).then(result=> {
    //   // console.log(result);
    // }).catch((error)=>{
    //   // console.log(error);
    // })

    // addLocation({email: 'east@gudyfoods.com', location: 'east'}).then(result=> {
    //   // console.log(result);
    // }).catch((error)=>{
    //   // console.log(error);
    // })
  
    // var makeHimAdmin = firebase.functions().httpsCallable('addAdmin');
    // makeHimAdmin({email: 'info@ivesmedicare.com'}).then(result=> {
    //    console.log(result);
    // }).catch((error)=>{
    //    console.log(error);
    // })

  }

  // makeAdmin(email)
  // {
  //   var makeHimAdmin = firebase.functions().httpsCallable('addAdmin');
  //   makeHimAdmin({email: '292960458@qq.com'}).then(result=> {
  //     // console.log(result);
  //   }).catch((error)=>{
  //     // console.log(error);
  //   })
  // }

  

  showErrorInfo(error) {
    
        let errorelement: HTMLElement = document.getElementById('errorParagraph') as HTMLElement;
        let errorbut: HTMLElement = document.getElementById('errorbut') as HTMLElement;
        errorelement.innerHTML = error;
        errorbut.click();
        


        // let incokelement: HTMLElement = document.getElementById('incorrectbut') as HTMLElement;
        // incokelement.hidden = false;
  }


  submitPasswordResetRequest() {
    // let passwordReset: HTMLElement = document.getElementById('passwordSubmitButton') as HTMLElement;
    // let passwordOk: HTMLElement = document.getElementById('passwordOkButton') as HTMLElement;
    let resultParagraph: HTMLElement = document.getElementById('resultParagraph') as HTMLElement;

    

    firebase.auth().sendPasswordResetEmail(this.forgotten_email).then((result) => {
      console.log(result)
      resultParagraph.innerHTML = "Password reset link has been sent into your email, please reset your pass and try log in again!";

    }).catch((error) => {
      console.log(error);
      resultParagraph.innerHTML = error.message;
      // An error happened.
    });

    // click()
    //     let errorbut: HTMLElement = document.getElementById('errorbut') as HTMLElement;
    //     errorelement.innerHTML = error;
    //     errorbut.click();
    


  }

  modalClosed() {
    let resultParagraph: HTMLElement = document.getElementById('resultParagraph') as HTMLElement;
    resultParagraph.innerHTML = "";
  }

}



